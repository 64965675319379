import * as React from "react";
import { Redirect, Route } from "react-router-dom";

// Created this for the partner dashboard
const BankRoute = ({ component, ...rest }: any) => {
    const token = localStorage.getItem("access_token");
    const authUser = JSON.parse(localStorage.getItem("user"));

    const routeComponent = (props: any) =>
        token && authUser?.role === "ADMINISTRATOR" ? (
            React.createElement(component, props)
        ) : (
            <Redirect to={{ pathname: "/" }} />
        );

    return <Route {...rest} render={routeComponent} />;
};

export default BankRoute;
