import React from "react";
import { object, string, ref } from "yup";
import { useDispatch, useSelector } from "react-redux";
import format from "date-fns/format";
import { Field, Form, Formik } from "formik";

import Settings from "../Settings";
import TextInput from "components/TextInput/TextInput";
import { changePasswordFn } from "slices/settingsSlice";
import {selectProfile} from 'slices/merchantSlice';

const SecurityScreen: React.FC = () => {
    const dispatch = useDispatch();
    const {permissions: profilePermissions} = useSelector(selectProfile);

    const user = JSON.parse(localStorage.getItem("user") || "");

    const handlePasswordChange = (values, { resetForm }) => {
        if (profilePermissions?.includes('UPDATE_MERCHANT_DETAIL')) {
            dispatch(
                changePasswordFn(values.password, () => {
                    resetForm();
                })
            );
        } else {
            return;
        }
    };

    return (
        <Settings>
            <div className="d-flex">
                <div>
                    <p className="mb-0">Security</p>
                    <p className="settings__lightText">Change your password</p>
                    <p className="settings__lightText">
                        Last changed {format(new Date(user?.updatedAt), "MMM dd, yyyy")}
                    </p>
                </div>
                <div>
                    <Formik
                        onSubmit={handlePasswordChange}
                        validationSchema={passwordUpdateSchema}
                        initialValues={{ password: "", confirm_password: "" }}
                    >
                        <Form className="ml-5">
                            <Field name="password" type="password" label="New Password" component={TextInput} />
                            <Field
                                type="password"
                                component={TextInput}
                                name="confirm_password"
                                label="Confirm Password"
                            />

                            {!!profilePermissions?.includes('UPDATE_MERCHANT_DETAIL') && <button type="submit" className="btn btn-outline-secondary">
                                Change password
                            </button>}
                        </Form>
                    </Formik>
                </div>
            </div>
        </Settings>
    );
};

const passwordUpdateSchema = object().shape({
    password: string().required("Password is required").min(6),
    confirm_password: string()
        .required("Confirm password is required")
        .oneOf([ref("password"), null], "Passwords must match"),
});

export default SecurityScreen;
