import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import config from "constants/api";
import routes from "routing/routes";
import styles from "./Navbar.module.css";
import LogoImg from "assets/images/new-logo.png";
import ArrowDown from "assets/images/arrowDown.svg";
import { setAccountModeFn } from "slices/configSlice";
import { selectMerchantList, selectProfile, getMerchantListFn, switchMerchantFn, profileFn } from "slices/merchantSlice";

const Navbar: React.FC = () => {
    const user = JSON.parse(localStorage.getItem("user") || "");
    const history = useHistory();

    const dispatch = useDispatch();

    const merchantListState = useSelector(selectMerchantList);
    const profileState = useSelector(selectProfile);

    const [toggleMerchant, setToggleMerchant] = useState(false);
    const [isProduction] = useState(localStorage.getItem(config.ACCOUNT_MODE) === "PRODUCTION");

    const fetchMerchantList = () => {
        dispatch(getMerchantListFn());
        dispatch(profileFn());
    };

    useEffect(fetchMerchantList, []);

    const handleSwitchMerchant = (merchant) => {
        dispatch(switchMerchantFn({ merchantId: merchant?.id }));
    };

    return (
        <>
            <div className={styles.navbar}>
                <div className={`${styles.navbar__content} container-fluid`}>
                    <div className={styles.profile__container}>
                        <div className={styles.navbar__rightContent__new} onClick={() => setToggleMerchant(!toggleMerchant)}>
                            <div className={styles.navbar__initials}>
                                {profileState?.data?.Merchant?.businessName?.split(" ")[0]?.slice(0, 1) || ""}
                                {profileState?.data?.Merchant?.businessName?.split(" ")[1]?.slice(0, 1) || ""}
                            </div>
                            <span className={styles.navbar__username__new}>{profileState?.data?.Merchant?.businessName}</span>
                            <img src={ArrowDown} alt="" className="ml-2" />
                        </div>
                        <div className={styles.merchant__list__container} style={{ display: toggleMerchant ? "block" : "none" }}>
                            {merchantListState?.data
                                ?.filter((each) => profileState?.data?.Merchant?.id !== each?.id)
                                ?.map((each) => {
                                    return (
                                        <div className={styles.merchant__list} onClick={() => handleSwitchMerchant(each)} key={each?.id}>
                                            <div className={styles.navbar__initials__new}>
                                                {each?.name?.split(" ")[0]?.slice(0, 1) || ""}
                                                {each?.name?.split(" ")[1]?.slice(0, 1) || ""}
                                            </div>
                                            {each?.name}
                                        </div>
                                    );
                                })}
                        </div>
                    </div>
                    <div className={styles.main__header__content}>
                        <div>
                            <Link to={routes.HOME}>
                                <img className={styles.navbar__logo} src={LogoImg} alt="Logo" />
                            </Link>
                        </div>
                        <div className="custom-control custom-switch">
                            <input
                                id="testMode"
                                type="checkbox"
                                className="custom-control-input"
                                checked={profileState?.data?.Merchant?.mode === "PRODUCTION" ? true : false}
                                onChange={(e) => {
                                    if (!e.target.checked) {
                                        dispatch(
                                            setAccountModeFn("SANDBOX", () => {
                                                localStorage.setItem(config.ACCOUNT_MODE, "SANDBOX");
                                                window.location.reload();
                                            })
                                        );
                                    } else {
                                        dispatch(
                                            setAccountModeFn("PRODUCTION", () => {
                                                localStorage.setItem(config.ACCOUNT_MODE, "PRODUCTION");
                                                window.location.reload();
                                            })
                                        );
                                    }
                                }}
                            />
                            <label className="custom-control-label" htmlFor="testMode">
                                {isProduction ? "Live Mode On" : "Test Mode On"}
                            </label>
                        </div>
                        <div className={styles.navbar__rightContent} onClick={() => history.push("/settings")}>
                            <div className={styles.navbar__initials}>
                                {user.lastName.slice(0, 1) || ""}
                                {user.firstName.slice(0, 1) || ""}
                            </div>
                            <span className={styles.navbar__username}>{user?.firstName + " " + user?.lastName}</span>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className={styles.merchant__overlay} style={{display: toggleMerchant ? 'block' : 'none'}} onClick={() => setToggleMerchant(false)} /> */}
        </>
    );
};

export default Navbar;
